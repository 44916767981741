import React from "react"
import { graphql } from "gatsby"
import Page from "../components/page"
import TextLeft from "../components/textLeft"

export default function AboutPhototailors({ location, data }) {
  return (
    <Page title={"About – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>CRAFTSMANSHIP</div>}
        subtitle=""
        text={
          <div>
            Whether you’re looking for your perfect wedding album, albums for parents or custom guestbooks you’re in the right spot. We’ve been manufacturing photo albums for over 10 years.
            <br/>
            <br/>
            Each of our photo albums is handmade ensuring that the end result is a piece of art. We strongly believe that your cherished memories should be kept where they belong – in one of our photo albums.
            <br/>
            <br/>
            Using our lay flat technology allows for a better storytelling. There is no gap between the pages so you can enjoy a perfect view of each photo.
            <br/>
            <br/>
            We use traditional premium photographic paper that lasts for a lifetime. Every time you open your album you will enjoy the same vibrant colors.
            <br/>
            <br/>
            Between something good and something great is attention to detail. That’s why we offer different styles of personalization: embossing, UV printing and laser engraving. You can have your initials, monogram or names on the cover for an extra personal touch.
          </div>
        }
        data={data}
        image={"about"}
      />


    </Page>
  )
}


export const query = graphql`
  query {
    
    about: file(relativePath: { eq: "about.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   
   
  }
`